<script setup lang="ts">
import type { Schemas } from "#shopware";

const props = defineProps<{
  content: {
    id: string;
    config: {
      heading: { value: string };
      categories: { value: string[] };
      showSlider: { value: boolean };
    };
  };
}>();

const heading = props.content.config.heading.value;
const categoryIds = props.content.config.categories.value;
const showSlider = props.content.config.showSlider?.value || false;

const { apiClient } = useShopwareContext();
const { data: categories } = await useAsyncData(
  `category-slider-${props.content.id}`,
  async () => {
    const { data } = await apiClient.invoke("readCategoryList post /category", {
      body: {
        ids: categoryIds,
        associations: {
          media: {},
          seoUrls: {},
        },
      },
    });
    return (data.elements ?? []) as Schemas["Category"][];
  },
);
</script>
<template>
  <ProductCategorySlider
    v-if="showSlider"
    :heading="heading"
    :categories="categories"
  />
  <ProductCategoryGrid v-else :heading="heading" :categories="categories" />
</template>
